import * as React from "react";
import Title from "./Title";
import Card from "./Card";
import { Link } from "gatsby";
import * as styles from "./projects.module.css";

const Projects = ({ projects, titleText, showLink }) => {
  return (
    <section className="section projects-page">
      <div className={styles.projectsContainer}>
        <Title titleText={titleText} />
        <div className="section-center projects-center">
          {projects.map((item, index) => {
            return <Card key={index} index={index} {...item} />;
          })}
        </div>
        {showLink && (
          <div className="btn-container">
            <Link
              to="/projectpage"
              className="sbtn liquid-btn blue-btn rounded-btn btn center-btn"
            >
              view projects
            </Link>
          </div>
        )}
      </div>
    </section>
  );
};

export default Projects;
