import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { FaGithubSquare, FaShareSquare } from "react-icons/fa";

const Project = ({
  desc,
  featured,
  title,
  githubUrl,
  siteUrl,
  image,
  index,
  stack,
}) => {
  const gatsbyImage = getImage(image.gatsbyImageData);
  return (
    <article className="project">
      <GatsbyImage
        image={gatsbyImage}
        alt={image.alt}
        className="project-img"
      />

      <div className="project-info">
        <span className="project-number">0{index + 1}.</span>
        <h3>{title}</h3>
        <p className="project-desc">{desc}</p>
        <div className="project-stack">
          {stack.map((item, index) => {
            return <span key={index}>{item.stack}</span>;
          })}
        </div>
        <div className="project-links">
          <a
            href={githubUrl}
            className="project-icon"
            aria-label="link to github"
          >
            <FaGithubSquare />
          </a>
          <a
            href={siteUrl}
            className="project-icon"
            aria-label="link to the project site"
          >
            <FaShareSquare />
          </a>
        </div>
      </div>
    </article>
  );
};

Project.propTypes = {
  title: PropTypes.string.isRequired,
  githubUrl: PropTypes.string.isRequired,
  siteUrl: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  stack: PropTypes.arrayOf(PropTypes.object).isRequired,
  // image: PropTypes.object.isRequired,
};

export default Project;
